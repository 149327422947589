import { render, staticRenderFns } from "./checkComplaint.vue?vue&type=template&id=f4cd1ed8&scoped=true&"
import script from "./checkComplaint.vue?vue&type=script&lang=js&"
export * from "./checkComplaint.vue?vue&type=script&lang=js&"
import style0 from "./checkComplaint.vue?vue&type=style&index=0&id=f4cd1ed8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4cd1ed8",
  null
  
)

export default component.exports