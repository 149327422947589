<template>
  <div class="check-complaint-page">
    <Header>
      <div class="search-container">
        <Search></Search>
      </div>
    </Header>
    <div class="complain-item-container">
      <Complaint-Item
        v-for="(item, index) in list"
        :key="index"
        :title="item.compType"
        :briefDescription="item.compContext"
        :images="item.compPhotos"
        :date="item.createTime"
        :person="item.createByName"
      ></Complaint-Item>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Search from "../../components/common/search/search.vue";
import ComplaintItem from "./components/complaintItem.vue";
import { fetchComplaintList } from "@/api/complaint";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    Header,
    Search,
    ComplaintItem,
    InfiniteLoading,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 5,
      total: 0,
      list: [],
    };
  },
  beforeMount() {
    this.id = this.$route.query.id;
  },
  methods: {
    async infiniteHandler($state) {
      await this.fetchData();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchData() {
      let response = [];
      let data = {
        kjTerminalNodeId: this.id,
        searchTotal: "",
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      };
      response = await fetchComplaintList(data);
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-complaint-page {
  min-height: 100vh;
  background: #fafbfc;
  text-align: left;
  .search-container {
    width: 281px;
    height: 44px;
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
  }
  .complain-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }
}
</style>
