<template>
  <div class="complaint-index-page">
    <Header></Header>
    <Form v-model="form1" class="form" ref="form1" :rows="rows"></Form>
    <CustomTextarea v-model="textarea" class="textarea-item" label="投诉内容" :required="true" :showLimit="false" />
    <Form v-model="form2" class="form" ref="form2" :rows="typeRows"></Form>
    <Button class="save-btn" size="full" @click="save">保存</Button>
    <ImgUpload class="img-upload" label="图片" :limit="8" />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Form from '@/components/common/form/index.vue'
import Button from '@/components/common/button/CustomButton.vue'
import CustomTextarea from '@/components/common/textArea/index.vue'
import ImgUpload from '@/components/common/imgUploadCard/index.vue'
import { submitComplaint } from '@/api/complaint'


export default {
  components: {
    Header,
    Form,
    Button,
    CustomTextarea,
    ImgUpload,
  },
  data() {
    return {
      rows: [
        { label: '投诉日期', key: 'complaintDate', type: 'date' },
        { label: '联系人', key: 'contacts', type: 'input'},
      ],
      typeRows: [
        { label: '投诉类型', key: 'complaintType', type: 'picker', data: [] },
      ],
      form1: {},
      form2: {},
      textarea: ''
    }
  },
  methods: {
    async save() {
      try {
        await submitComplaint({

        })
        this.$toast('获取成功');
      }
      catch (e) {
        this.$toast(e.message || '获取失败');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.complaint-index-page {
  min-height: 100vh;
  background: #FAFBFC;
  .form, .textarea-item, .img-upload{
    margin-top: 12px;
  }
  .save-btn {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>