<template>
  <div class="complaint-item-container">
    <div class="title">{{ title }}</div>
    <div class="person">投诉人：{{ person }}</div>
    <div class="title">投诉内容：</div>
    <div class="brief-description">{{ briefDescription }}</div>
    <div class="image-viewer-container">
      <ImageViewer :images="images ? images.split(',') : []" :upload="false" />
    </div>
    <div class="date">{{ date }}</div>
  </div>
</template>

<script>
import ImageViewer from "@/components/common/imageViewer/index.vue";

export default {
  components: {
    ImageViewer,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    briefDescription: {
      type: String,
      default: "",
    },
    images: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: '',
    },
    person: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="scss" scoped>
.complaint-item-container {
  width: 351px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  opacity: 1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 12px 12px 11px;
  margin-bottom: 12px;
  .title, .person {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #2c2c2c;
  }
  .brief-description {
    width: 100%;
    min-height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #2c2c2c;
    margin-top: 17px;
  }
  .image-viewer-container {
    width: 100%;
    margin-top: 6px;
  }
  .date {
    width: 100%;
    margin-top: 14px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #818181;
  }
}
</style>
